import React from 'react';

import { compose, space, color } from 'styled-system';

import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import Link from '../Link';

import { PHONE_NUMBER } from '../../constants';

const CallToOrder = () => {
  return (
    <Flex
      as="h2"
      flexDirection={{ mobile: 'column', laptop: 'row' }}
      mt={100}
      mb={{ mobile: 30, laptop: 50 }}
      px={29}
    >
      <FormattedMessage id="menu.want to order" />
      <Link
        external
        to={`tel: ${PHONE_NUMBER}`}
        css={compose(space, color)}
        ml={{ mobile: 0, laptop: 10 }}
        color="green"
      >
        <FormattedMessage id="menu.call" />
      </Link>
    </Flex>
  );
};

export default CallToOrder;
