import React from 'react';

import withLayout from '../layout';
import { Subheader, MenuComponent, CallToAction, CallToOrder } from '../components/menuPage';
import { GridWidthBox } from '../components';

const Menu = (props) => {
  return (
    <>
      <GridWidthBox
        flex
        as="section"
        mx="auto"
        mb={{ mobile: 50, laptop: 100 }}
        flexDirection="column"
        alignItems="center"
      >
        <CallToOrder />
        <Subheader />
        <MenuComponent />
      </GridWidthBox>
      <CallToAction />
    </>
  );
};

Menu.propTypes = {};

const customProps = {
  localeKey: 'menu',
  keywords: ['menu'],
};

export default withLayout(customProps)(Menu);
